import Modal from "@cloudscape-design/components/modal";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Alert from "@cloudscape-design/components/alert";
import Input from "@cloudscape-design/components/input";
import FormField from "@cloudscape-design/components/form-field";
import {useEffect, useState} from "react";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import {BASE_URL} from "../config";
import Tiles from "@cloudscape-design/components/tiles";
import TextContent from "@cloudscape-design/components/text-content";
// import StatusIndicator from "@cloudscape-design/components/status-indicator";
// import Popover from "@cloudscape-design/components/popover";
// import Icon from "@cloudscape-design/components/icon";

const alertInfo = (userInfo) => {
    return {
        SIM_TOOLKIT: {
            title: 'M-Pesa SIM Toolkit',
            description: `Go to M-Pesa and select Lipa na ${userInfo.PayBill === "" ? 'till' : 'paybill'} then enter the following details and this amount`,
        },
        EXPRESS: {
            title: 'M-Pesa Express',
            description: 'A USSD pop-up will appear on your phone. Please enter PIN to complete the transaction of',
        },
    }
}

export default function RenewPackage({subdomain, visible, setVisible, userInfo, addFlashbar}) {

    const [inputValue, setInputValue] = useState("");
    let maskedPhoneNumber = userInfo.Customer.PhoneNumber
    let topUpAmount = parseInt(userInfo.CustomerPackage.Price, 10) - parseInt(userInfo.Customer.Balance, 10)
    let [isLoading, setIsLoading] = useState(false)

    const [paymentOption, setPaymentOption] = useState("EXPRESS");

    useEffect(() => {
        // if (userInfo.Customer.ID !== "")
        //     getQRCode()
    }, [])

    let topUp = async () => {
        setIsLoading(true)
        let data = {
            PhoneNumber: inputValue,
            PackageID: userInfo.CustomerPackage.ID,
            Amount: topUpAmount.toString(),
            CustomerPhone: inputValue.length === 0
        }

        let opt = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        //fetch request post
        let res = await fetch(`${BASE_URL}/site/${subdomain}/top-up/${userInfo.Customer.ID}`, opt)
        if (res.ok) {
            setVisible(false)
            addFlashbar()
        }

        setIsLoading(false)
    }

    let getQRCode = async () => {
        let res = await fetch(`${BASE_URL}/site/${subdomain}/qr-code/${userInfo.Customer.ID}/${topUpAmount}`)
        console.log(res)
        if (res.ok) {
            console.log(await res.json())
        }
    }

    let ai = alertInfo(userInfo)[paymentOption]

    return <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={paymentOption === "EXPRESS" &&
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">

                    <Button variant="primary"
                            loading={isLoading}
                            disabled={inputValue.length < 10 && inputValue.length > 0}
                            onClick={topUp}>
                        Pay Now
                    </Button>
                </SpaceBetween>
            </Box>
        }
        header="Renew Subscription"
    >
        <ColumnLayout>
            <div>
                You are about to renew your subscription
                for <strong>{userInfo.CustomerPackage.Name}</strong> for <strong>Kshs. {userInfo.CustomerPackage.Price}</strong>
            </div>
            <Tiles
                onChange={({detail}) => setPaymentOption(detail.value)}
                value={paymentOption}
                items={[
                    {
                        label: "M-Pesa SIM Toolkit",
                        value: "SIM_TOOLKIT",
                        description: 'View M-Pesa payment instructions'
                    },
                    {
                        label: "M-Pesa Express",
                        value: "EXPRESS",
                        description: 'Send USSD/STK push to your mobile number'
                    },
                ]}
            />

            <Alert
                variant="info"
                dismissAriaLabel="Close alert"
                header={ai.title}
            >{ai.description} <strong>Ksh. {topUpAmount}</strong>.
            </Alert>
            {paymentOption === "EXPRESS" && <>

                <ColumnLayout columns={2}>
                    <FormField
                        description="Phone number to send USSD pop-up."
                        label="Phone number"
                    >
                        <Input
                            value={inputValue}
                            type={`number`}
                            min={0}
                            placeholder={maskedPhoneNumber}
                            onChange={event =>
                                setInputValue(event.detail.value)
                            }
                        />
                    </FormField>
                </ColumnLayout>
            </>}

            {paymentOption === "SIM_TOOLKIT" && <>

                <ColumnLayout disableGutters columns={1}>
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label"
                                 fontSize={"heading-s"}
                                 margin={{top: "s"}}>{userInfo.PayBill === "" ? 'Till Number' : 'Pay Bill'}</Box>
                            <TextContent>
                                <p>
                                    {/*<Popover*/}
                                    {/*    dismissButton={false}*/}
                                    {/*    position="top"*/}
                                    {/*    size="small"*/}
                                    {/*    triggerType="custom"*/}
                                    {/*    content={*/}
                                    {/*        <StatusIndicator type="success">*/}
                                    {/*            Paybill copied*/}
                                    {/*        </StatusIndicator>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Icon name="copy" variant="subtle"/>*/}
                                    {/*</Popover>*/}
                                    {userInfo.PayBill === "" ? userInfo.TillNumber : userInfo.PayBill} </p>
                            </TextContent>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label"
                                 fontSize={"heading-s"}
                                 margin={{top: "s"}}>Account</Box>
                            <TextContent>

                                <p>
                                    {/*<Popover*/}
                                    {/*    dismissButton={false}*/}
                                    {/*    position="top"*/}
                                    {/*    size="small"*/}
                                    {/*    triggerType="custom"*/}
                                    {/*    content={*/}
                                    {/*        <StatusIndicator type="success">*/}
                                    {/*            Account copied*/}
                                    {/*        </StatusIndicator>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Icon name="copy" variant="subtle"/>*/}
                                    {/*</Popover>  */}
                                    {userInfo.DefaultPayment === "PHONE" ? `Phone number ending with ${maskedPhoneNumber}` : userInfo.Customer.Username}
                                </p>
                            </TextContent>
                        </div>
                    </SpaceBetween>

                </ColumnLayout>
            </>}
        </ColumnLayout>
    </Modal>
}
