import * as React from "react";
import TopNavigation from "@cloudscape-design/components/top-navigation";

export default () => {
    return (
        <TopNavigation
            identity={{
                href: "#",
                title: "oneISP",
            }}
            utilities={[
                // {
                //     type: "button",
                //     text: "Login",
                //     href: "https://example.com/",
                //     external: true,
                //     externalIconAriaLabel: " (opens in a new tab)"
                // },

            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}
