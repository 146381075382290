import "@cloudscape-design/global-styles/index.css"
import {applyDensity, Density} from '@cloudscape-design/global-styles';
import AppLayout from "@cloudscape-design/components/app-layout"
import Container from "@cloudscape-design/components/container"
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import H from "@cloudscape-design/components/header";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import TopNav from './app-layout/top-navigation'
import {Footer} from "./app-layout/footer";
import Header from './components/header'
import RenewPackage from "./components/renew-package";
import {useEffect, useState} from "react";
import Flashbar from "@cloudscape-design/components/flashbar";
import {BASE_URL} from "./config";
import moment from 'moment';
import ValueWithLabel from "./components/key-pair";
import Alert from "@cloudscape-design/components/alert";
import Button from "@cloudscape-design/components/button";

// apply a content density mode
applyDensity(Density.Comfortable);

function App() {

    const [visible, setVisible] = useState(false);
    const [userInfo, setUserInfo] = useState(null)
    const [flashBars, setFlashbBar] = useState([])

    let message = buildStatuses(userInfo)

    let params = window.location.pathname.split('/')
    let ispSubDomain = params[1]
    let siteID = params[3]

    let getSiteData = async () => {

        let url = `${BASE_URL}/site/${ispSubDomain}`
        if (siteID.trim()!=="")
            url+=`/${siteID ?? ''}/check-status`

        let userInfo = await fetch(url).then((res) => res.json())
        setUserInfo(userInfo)
        setVisible(userInfo.Customer.ConnectionStatus === "UserExpired")
    }

    let addFlashbar = () => {

        setFlashbBar((prev) => {
            return [...prev, {
                type: 'success',
                header: 'USSD Push sent successfully',
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashbBar([]),
            }]
        })

    }

    useEffect(() => {
        if (!userInfo)
            getSiteData()
    }, [userInfo])


    if (!userInfo) return <></>

    let conStatus = userInfo.Customer.ConnectionStatus
    let mess = message[conStatus]

    return (

        <div>

            <TopNav/>

            <AppLayout
                navigationHide={true}
                toolsHide={true}
                disableBodyScroll={true}
                notifications={<Flashbar
                    items={conStatus === "UserExpired" || conStatus === "ISPCapacityExceeded" ? [message[conStatus], ...flashBars] : flashBars}/>}
                contentHeader={<Header renewPackage={setVisible} userInfo={userInfo}/>}
                disableContentHeaderOverlap={false}
                disableContentPaddings={false}
                content={
                    <>
                        {conStatus === "" ?
                            buildSupport(userInfo) :
                            (conStatus === "UserExpired" || conStatus === "UserDisabled" || conStatus === "UserOk" || conStatus === "ISPCapacityExceeded" ?
                                    buildOkExpired(conStatus, userInfo, mess) :
                                    buildWrongDetails(userInfo)
                            )
                        }
                    </>
                }
            />


            {userInfo &&
                <RenewPackage
                    subdomain={ispSubDomain}
                    visible={visible}
                    setVisible={setVisible}
                    userInfo={userInfo}
                    addFlashbar={addFlashbar}/>}

            <Footer/>
        </div>
    );
}

function buildOkExpired(conStatus, userInfo, mess) {


    let periodUsage = userInfo.Customer.PeriodUsage.split("/")
    let upload = periodUsage[1]
    let download = periodUsage[0]

    return <div>
        {userInfo.Customer.ID !== "" && <Container
            header={<H variant="h2">Basic Information</H>}>
            <ColumnLayout variant="text-grid" columns={3}>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Name">
                        {userInfo.Customer.FirstName} {userInfo.Customer.LastName}
                    </ValueWithLabel>
                    <ValueWithLabel label="Account Number">
                        {userInfo.Customer.Username}
                    </ValueWithLabel>
                    <ValueWithLabel label="Status">
                        <StatusIndicator type={mess.statusType}>
                            {mess.status}
                        </StatusIndicator>
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">
                    <ValueWithLabel label="Package">
                        {userInfo.CustomerPackage.Name}
                    </ValueWithLabel>
                    <ValueWithLabel label="Usage">
                        {download} download | {upload} upload
                    </ValueWithLabel>
                    <ValueWithLabel label="Expiry Date">
                        {moment(userInfo.Customer.Expiry).format("HH:mm MMM DD, Y")}
                    </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween size="l">

                    <ValueWithLabel label="Wallet Balance">
                        Ksh. {userInfo.Customer.Balance}
                    </ValueWithLabel>
                    {userInfo.Customer.DeviceType !== "" && <ValueWithLabel label="Device">
                        {userInfo.Customer.DeviceType}
                    </ValueWithLabel>}
                </SpaceBetween>
            </ColumnLayout>
        </Container>}
    </div>
}

function buildWrongDetails(userInfo) {
    return <div>
        <Container header={<H variant="h2">Error Information</H>}>


            <ColumnLayout>
                <Alert
                    type="error"
                    dismissAriaLabel="Close alert"
                    header={`Wrong or Invalid credentials`}
                > Your router credentials doesnt match with our database please
                    contact <strong>+{userInfo.Phone}</strong> or <strong>{userInfo.Email}</strong> for
                    assistance
                </Alert>


                <ColumnLayout variant="text-grid" columns={3}>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="MAC Address">
                            N/A
                        </ValueWithLabel>
                        <ValueWithLabel label="IP">
                            N/A
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Device">
                            N/A
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </ColumnLayout>
        </Container>
    </div>
}

function buildSupport(userInfo) {
    return <div>
        <Container
            header={<H variant="h2">Notice</H>}>
            <p>Please contact our support
                through <strong>+{userInfo.Phone}</strong> or <strong>{userInfo.Email}</strong> for
                assistance
            </p>
        </Container>
    </div>
}


function buildStatuses(userInfo) {

    let phoneNumber = userInfo ? userInfo.PhoneNumber : ''

    return {
        UserOk: {
            status: 'Active',
            statusType: 'success',
            message: 'Please renew your subscription'
        },
        UserExpired: {
            type: 'error',
            status: 'Expired',
            statusType: 'pending',
            header: 'Your subscription has expired',
            message: 'Please renew your subscription'
        },
        UserDisabled: {
            type: 'error',
            status: 'Disabled',
            statusType: 'stopped',
            header: 'Account has been de-activated',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserNotFoundPPP: {
            type: 'error',
            header: 'Invalid PPPoE username Credentials',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserNotFoundStatic: {
            type: 'error',
            header: 'Invalid IP Credentials',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserNotFoundDhcp: {
            type: 'error',
            header: 'Invalid MAC Credentials',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserWrongPassword: {
            type: 'error',
            header: 'Invalid password entered',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserNotAllowedToSite: {
            type: 'error',
            header: 'Location changed',
            message: `Please  contact us through ${phoneNumber}`
        },
        UserNotAllowedWithMac: {
            type: 'error',
            header: 'Device changed',
            message: `Please  contact us through ${phoneNumber}`
        },
        ISPCapacityExceeded: {
            header: "Failed to connect",
            type: "error",
            content:
                "Please reboot router or click connect to try again. If it continues like this reach us at .",
            action: <Button>CONNECT</Button>,
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => {
            },
        },
    }
}

export default App;
