import Box from "@cloudscape-design/components/box";

export default function ValueWithLabel({label, children}) {
    return (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{children}</div>
        </div>
    );
}
