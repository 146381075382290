import * as React from "react";
import Header from "@cloudscape-design/components/header";
import Button from "@cloudscape-design/components/button";
import SpaceBetween from "@cloudscape-design/components/space-between";

export default function ({renewPackage, userInfo}) {

    return (
        <Header
            variant="h1"
            actions={
                <SpaceBetween direction="horizontal" size="xs">

                    {userInfo.Customer.ConnectionStatus === "UserExpired"   && <Button variant="primary" onClick={() => {
                        renewPackage(true)
                    }}>
                        Renew Subscription
                    </Button>}
                </SpaceBetween>
            }
        >
            {userInfo.BusinessName}
        </Header>
    );
}
